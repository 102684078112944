import Vue from "vue";

export async function index() {
  const response = await Vue.prototype.$http.get(
    `/config/lead_statuses/conversion/statuses`
  );

  return response.data.data;
}

export async function updateUnity(form) {
  const response = await Vue.prototype.$http.put(
    `/config/lead_statuses/conversion/unity`,
    form
  );

  return response.data.data;
}

export async function updateInsurer(form) {
  const response = await Vue.prototype.$http.put(
    `/config/lead_statuses/conversion/insurer`,
    form
  );

  return response.data.data;
}
