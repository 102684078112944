import Vue from "vue";

export async function index(entity) {
  const response = await Vue.prototype.$http.get(
    `/config/lead_statuses/${entity}`
  );

  return response.data.data;
}

export async function store(entity, form) {
  const response = await Vue.prototype.$http.post(
    `/config/lead_statuses/${entity}`,
    form
  );

  return response.data.data;
}

export async function update(entity, id, form) {
  const response = await Vue.prototype.$http.put(
    `/config/lead_statuses/${entity}/${id}`,
    form
  );

  return response.data.data;
}

export async function destroy(entity, id) {
  const response = await Vue.prototype.$http.delete(
    `/config/lead_statuses/${entity}/${id}`
  );

  return response;
}
