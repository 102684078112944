<style lang="scss" scoped>
.status-table {
  td {
    // border: 1px solid #f1f1f1;
  }
}
</style>

<template>
  <ConfigPage :max-width="900">
    <ConfigItem title="Status">
      <v-card outlined>
        <v-list>
          <v-list-item
            link
            class="primary--text"
            :to="{ name: 'configs.leads.statuses.insurers' }"
          >
            <v-list-item-content>
              <v-list-item-title> Portal das Companhias </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon color="primary">mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            :to="{ name: 'configs.leads.statuses.unities' }"
            link
            class="primary--text"
          >
            <v-list-item-content>
              <v-list-item-title> Portal das Unidades </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon color="primary">mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </ConfigItem>

    <ConfigItem
      title="Conversão"
      description="Configura os status a serem exibidos no Portal das Companhias, Portal das Unidades de acordo com o Portal HCM"
      class="mt-10"
    >
      <v-card flat>
        <v-alert type="warning" class="mb-4" text outlined>
          Apenas leads com status convertidos serão exibidas.
        </v-alert>
      </v-card>

      <v-card outlined>
        <v-simple-table class="status-table">
          <thead>
            <tr>
              <th>HCM</th>
              <th>Companhia</th>
              <th>Unidade</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="status in statuses"
              :key="status.id"
              :class="{ 'warning--text': isStatusWarning(status) }"
            >
              <td :title="getTitleMessage(status)">{{ status.name }}</td>
              <td width="310px">
                <v-autocomplete
                  single-line
                  label="---"
                  :items="insurerStatuses"
                  :value="status.insurer_status_id"
                  @change="updateInsurerStatus(status, $event)"
                  item-text="status"
                  item-value="id"
                  hide-details
                  dense
                  rounded
                  clearable
                ></v-autocomplete>
              </td>
              <td width="310px">
                <v-autocomplete
                  single-line
                  label="---"
                  :items="unityStatuses"
                  :value="status.unity_status_id"
                  @change="updateUnitiesStatus(status, $event)"
                  item-text="status"
                  item-value="id"
                  hide-details
                  rounded
                  dense
                  clearable
                ></v-autocomplete>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </ConfigItem>
  </ConfigPage>
</template>

<script>
import ConfigPage from "@/layouts/configs/ConfigPage";
import ConfigItem from "@/layouts/configs/ConfigItem";
import ToastsMixin from "@/mixins/ToastsMixin";

import {
  index,
  updateUnity,
  updateInsurer,
} from "@/services/configs/leads/statuses/status_conversion";

import { index as indexExternal } from "@/services/configs/leads/statuses/external_statuses";

export default {
  mixins: [ToastsMixin],

  components: {
    ConfigPage,
    ConfigItem,
  },

  props: {},

  data() {
    return {
      statuses: [],
      unityStatuses: [],
      insurerStatuses: [],
    };
  },

  methods: {
    async index() {
      this.statuses = await index();
    },

    async indexUnities() {
      this.unityStatuses = await indexExternal("unities");
    },

    async indexInsurers() {
      this.insurerStatuses = await indexExternal("insurers");
    },

    async updateInsurerStatus(status, insurerStatusId) {
      await updateInsurer({
        lead_status_id: status.id,
        insurer_status_id: insurerStatusId,
      });
      this.toast("Salvo!");
      this.index();
    },

    async updateUnitiesStatus(status, unityStatusId) {
      await updateUnity({
        lead_status_id: status.id,
        unity_status_id: unityStatusId,
      });
      this.toast("Salvo!");
      this.index();
    },

    isStatusWarning(status) {
      return !status.unity_status_id || !status.insurer_status_id;
    },

    getTitleMessage(status) {
      if (!status.unity_status_id && !status.insurer_status_id) {
        return `Leads no status ${status.name} serão vistas somente no Portal HCM`;
      }

      if (!status.unity_status_id) {
        return `Leads no status ${status.name} não serão vistas no Portal das Unidades`;
      }

      if (!status.insurer_status_id) {
        return `Leads no status ${status.name} não serão vistas no Portal das Companhias`;
      }
    },
  },

  computed: {},

  created() {
    this.index();

    this.indexInsurers();
    this.indexUnities();
  },
};
</script>
